import { CreateGuesser } from "@api-platform/admin";
import { AutocompleteInput, ReferenceInput, DateTimeInput } from "react-admin";

const SportEventCreate = props => (
    <CreateGuesser {...props}>
        <ReferenceInput
            source="homeTeam"
            reference="sport_teams"
            label="Home Team"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="fullName" />
        </ReferenceInput>
        <ReferenceInput
            source="awayTeam"
            reference="sport_teams"
            label="Away Team"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="fullName" />
        </ReferenceInput>

        <DateTimeInput source="startTime" locale="fr-FR" options={{ format: 'DD/MM/YYYY, HH:mm', ampm: false, clearable: true }} />
    </CreateGuesser>
);

export default SportEventCreate;
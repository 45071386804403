import { ListGuesser } from "@api-platform/admin";
import { ReferenceField, TextField, DateField } from "react-admin";

const SportEventList = (props) => (
    <ListGuesser {...props}>
        <DateField source="startTime" locales="fr-FR" showTime />
        <ReferenceField label="Home Team" source="homeTeam" reference="sport_teams">
            <TextField source="fullName" />
        </ReferenceField>
        <ReferenceField label="Away Team" source="awayTeam" reference="sport_teams">
            <TextField source="fullName" />
        </ReferenceField>
    </ListGuesser>
);

export default SportEventList;
import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import { ImageInput, ImageField } from "react-admin";

const ClubCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source="name" />
        <ImageInput source="file" label="Image" accept="image/*">
            <ImageField source="src" title="title" />
        </ImageInput>
    </CreateGuesser>
);

export default ClubCreate;
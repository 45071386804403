import TookCreate from "./TookCreate";
import TookEdit from "./TookEdit";
import TookList from "./TookList";

const props = {
    list: TookList,
    create: TookCreate,
    edit: TookEdit
};

export default props;
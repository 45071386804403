import { EditGuesser, InputGuesser } from "@api-platform/admin";
import { AutocompleteInput, ReferenceInput } from "react-admin";

const SportTeamEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="name" />
        <ReferenceInput
            source="club"
            reference="clubs"
            label="Club"
            filterToQuery={searchText => ({ title: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </EditGuesser>
);

export default SportTeamEdit;

import React from 'react';
import { Link } from 'react-router-dom';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
    button: {
        marginTop: '1em',
        marginBottom: '1em',
    }
};

const AddSportTeamButton = ({ classes, record }) => (
    <Button
        className={classes.button}
        variant="contained"
        component={Link}
        to={`/sport_teams/create?club_id=${record.id}`}
        label="Ajouter une équipe"
        title="Ajouter une équipe"
    >
        <SportsSoccerIcon />
    </Button>
);

export default withStyles(styles)(AddSportTeamButton);
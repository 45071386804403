import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import { AutocompleteInput, ReferenceInput } from "react-admin";

const TookCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source="title" />
        <ReferenceInput
            source="sportEvent"
            reference="sport_events"
            label="SportEvent"
        >
            <AutocompleteInput optionText="sportEventName" />
        </ReferenceInput>
        <InputGuesser source="bringedBy" />
    </CreateGuesser>
);

export default TookCreate;
import { FieldGuesser, ListGuesser } from "@api-platform/admin";
import { ReferenceField, TextField } from "react-admin";

const TookList = (props) => (
    <ListGuesser {...props}>
        <FieldGuesser source="title" />
        <ReferenceField label="SportEvent" source="sportEvent" reference="sport_events">
            <TextField source="sportEventName" />
        </ReferenceField>
        <FieldGuesser source="bringedBy" />
    </ListGuesser>
);

export default TookList;
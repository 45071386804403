import { FieldGuesser, ListGuesser } from "@api-platform/admin";
import { ReferenceManyField, ReferenceField, SingleFieldList, ChipField, ImageField } from "react-admin";

const ClubList = (props) => (
    <ListGuesser {...props}>
        <FieldGuesser source="name" />
        <ReferenceManyField
            label="Equipes"
            reference="sport_teams"
            target="club"
        >
            <SingleFieldList>
                <ChipField source="fullName" />
            </SingleFieldList>
        </ReferenceManyField>
        <ReferenceField label="Logo" source="logo" reference="media_objects">
            <ImageField source="contentUrl" />
        </ReferenceField>
    </ListGuesser>
);

export default ClubList;
import { EditGuesser, InputGuesser } from "@api-platform/admin";
import { AutocompleteInput, ReferenceInput } from "react-admin";

const TookEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="title" />
        <ReferenceInput
            source="sportEvent"
            reference="sport_events"
            label="SportEvent"
        >
            <AutocompleteInput optionText="sportEventName" />
        </ReferenceInput>
        <InputGuesser source="bringedBy" />
    </EditGuesser>
);

export default TookEdit;
import {
    HydraAdmin,
    ResourceGuesser
} from "@api-platform/admin";
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import SportsIcon from '@material-ui/icons/Sports';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import HomeIcon from '@material-ui/icons/Home';

import clubs from './clubs';
import mediaobjects from './media_objects';
import sportevents from './sport_events';
import sportteams from './sport_teams';
import tooks from './tooks';


/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default () => (
    <HydraAdmin entrypoint={process.env.REACT_APP_API_ENTRYPOINT}>
        <ResourceGuesser name="tooks" {...tooks}
            icon={LocalDrinkIcon}
        />
        <ResourceGuesser name="sport_events" {...sportevents}
            options={{ label: 'Matchs' }}
            icon={SportsIcon}
        />
        <ResourceGuesser name="sport_teams" {...sportteams}
            options={{ label: 'Équipe' }}
            icon={SportsSoccerIcon}
        />
        <ResourceGuesser name="clubs" {...clubs}
            icon={HomeIcon} />
        <ResourceGuesser name="users" />
        <ResourceGuesser name="cliques" />
        <ResourceGuesser name="media_objects" {...mediaobjects} />
    </HydraAdmin >
);
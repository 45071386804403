import { FieldGuesser } from "@api-platform/admin";
import { ReferenceField } from "react-admin";
import AddSportTeamButton from "./AddSportTeamButton";

import React from 'react';
import {
    Datagrid,
    ShowButton,
    ReferenceManyField,
    ImageField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField
} from 'react-admin';

const ClubShow = props => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Informations">
                <TextField source="id" />
                <FieldGuesser source={"name"} addLabel={true} />

                <ReferenceField label="Logo" source="logo" reference="media_objects">
                    <ImageField source="contentUrl" />
                </ReferenceField>
            </Tab>
            <Tab label="Equipes" path="sport_teams">
                <ReferenceManyField
                    addLabel={false}
                    reference="sport_teams"
                    target="club"
                >
                    <Datagrid>
                        <TextField source="fullName" />
                        <ShowButton />
                    </Datagrid>
                </ReferenceManyField>
                <AddSportTeamButton />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default ClubShow;
import SportEventCreate from "./SportEventCreate";
import SportEventEdit from "./SportEventEdit";
import SportEventList from "./SportEventList";

const props = {
    list: SportEventList,
    create: SportEventCreate,
    edit: SportEventEdit
}

export default props;
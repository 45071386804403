import SportTeamCreate from "./SportTeamCreate";
import SportTeamEdit from "./SportTeamEdit";
import SportTeamList from "./SportTeamList";

const props = {
    list: SportTeamList,
    create: SportTeamCreate,
    edit: SportTeamEdit
}

export default props;
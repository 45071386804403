import { FieldGuesser, ListGuesser } from "@api-platform/admin";
import { ImageField } from "react-admin";

const MediaObjectList = (props) => (
    <ListGuesser {...props} >
        <FieldGuesser source="title" />
        <FieldGuesser source="contentUrl" />
        <ImageField label="logo" source="contentUrl" />
    </ListGuesser>
);

export default MediaObjectList;
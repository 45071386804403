import { FieldGuesser, ListGuesser } from "@api-platform/admin";
import { ReferenceField, TextField } from "react-admin";

const SportTeamList = (props) => (
    <ListGuesser {...props}>
        <ReferenceField label="Club" source="club" reference="clubs">
            <TextField source="name" />
        </ReferenceField>
        <FieldGuesser source="fullName" />
    </ListGuesser>
);

export default SportTeamList;
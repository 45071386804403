import { InputGuesser } from "@api-platform/admin";
import { Create, SimpleForm } from "react-admin";
import { parse } from "query-string";
import ClubReferenceInput from "./ClubReferenceInput";

const SportTeamCreate = props => {
    // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
    const { club_id: club_id_string } = parse(props.location.search);

    const club_id = club_id_string ? club_id_string : "";

    // redirect to club/sport_teams
    const redirect = club_id ? `/clubs/${club_id}/show/sport_teams` : "show";

    return (
        <Create {...props}>
            <SimpleForm
                redirect={redirect}
                initialValues={{ club: club_id }}>
                <InputGuesser label="Nom de l'équipe" source="name" />
                <ClubReferenceInput
                    source="club"
                    reference="clubs"
                    allowEmpty
                />
            </SimpleForm>
        </Create >
    );
};

export default SportTeamCreate;